<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CCard>
          <CCardHeader class="d-flex justify-content-between" style="font-size: 18px;">
            <p>Alertas</p>
            <p><span style="font-weight: 500;">Total:</span> {{totalAlerts}}</p>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              v-show="!loading"
              hover
              striped
              :items="items"
              :fields="fields"
              :items-per-page="limit"
              @row-clicked="goToAlert"
              index-column
              clickable-rows
            >
              <template #name="data">
                <td>{{data.item.user.name}}</td>
              </template>
              <template #email="data">
                <td>{{data.item.user.email}}</td>
              </template>
              <template #estado="data">
                <td>
                  <CBadge :color="getBadge(data.item.estado)">{{getEstado(data.item.estado)}}</CBadge>
                </td>
              </template>
<!--               <template #actions="data">
                <CButton
                  color="warning"
                  size="sm"
                  class="m-1"
                  @click="updateUser(data.item, $event)"
                >
                  <font-awesome-icon icon="user-edit" />
                </CButton>
                <CButton
                  color="danger"
                  size="sm"
                  class="m-1"
                  @click="deleteUser(data.item.id, $event)"
                >
                  <font-awesome-icon icon="trash-alt" />
                </CButton>
              </template> -->
            </CDataTable>
            <div 
              v-if="loading"
              class="d-flex flex-column justify-content-center align-items-center" style="min-height: 300px;">
              <CSpinner
                style="width:4rem;height:4rem;"
                color="danger"
              />
              <p class="text-secondary my-2">Cargando datos ...</p>
            </div>
              <CPagination
                :active-page.sync="page"
                :pages="Math.ceil(totalAlerts / limit)"
                align="center"
                size="lg"
              />
          </CCardBody>
        </CCard>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import Pagination from '../../helpers/Pagination'
import { getAlerts } from '../../api/alerts'

export default {
  name: 'Alerts',
  components: {Pagination},
  data: () => {
    return {
      items: [],
      dataLoading: true,
      fields: [
        { key: 'id', label: '#' },
        { key: 'name', label: 'Usuario' },
        { key: 'email' },
        { key: 'estado' },
        { key: 'created_at', label: 'Creada' },
        //{ key: 'actions', label: 'Acciones' }
      ],
      page: 1,
      limit: 10,
      totalAlerts: 0,
      updatingUser: null,
      showModal: false,
      loading: true
    }
  },
  mounted(){
    this.getAlerts()
  },
  methods: {
    goToAlert(alert){
      this.$router.push({path: 'alertas/' + alert.id})
    },
    closeModal(){
      this.showModal = false
      this.getUsers()
    },
    updateUser(user, e){
      e.stopPropagation()
      this.updatingUser = user
      this.showModal = true
    },
    deleteUser(id, e){
      e.stopPropagation()
      console.log(id)
      const data = {id}
      const self = this
      deleteUser(data)
        .then(function(resp){
          console.log(resp)
          self.getUsers()
        })
        .catch(function(error){
          console.log(error)
        })
    },
    getAlerts(){
      const data = {
        limit: this.limit,
        page: this.page
      }
      const self = this
      this.loading = true
      getAlerts(data)
        .then(function(resp){
          self.items = resp.data
          self.loading = false
          self.totalAlerts = resp.total
        })
        .catch(function(error){
          console.log(error)
        })
    },
    getBadge (status) {
      return status === 0 ? 'primary'
        : status === 1 ? 'danger'
        : status === 2 ? 'warning' : null
    },
    getEstado (status) {
      return status === 0 ? 'Activa'
        : status === 1 ? 'Cerrada'
        : status === 2 ? 'Acción Realizada' : null
    }
  },
  watch: {
    page: _.debounce(function (page) {
      this.getAlerts();
    }, 500),
  }
}
</script>